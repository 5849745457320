<template>
  <v-app id="payment">
    <!-- <header-register /> -->
    <v-container fluid>
      <div class="main-container">
        <div class="py-12">
          <v-row>
            <v-col
              class="txt36-black-bold"
              cols="12"
            >
              Selamat Anda Telah Berhasil Mendaftar
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="text-capitalize"
                color="#36AC87"
                block
                x-large
                :disabled="disableButton"
                small
                @click="submit"
              >
                <span class="txt-btn-login"> Login Sekarang </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-dialog
        v-model="dialog1"
        width="500"
      >
        <v-card>
          <v-row>
            <v-col class="d-flex justify-space-between">
              <span class="txt16-black-bold">
                Pembayaran
              </span>
              <span
                style="cursor: pointer"
                @click="dialog1 = false"
              >
                <v-icon>mdi-close</v-icon>
              </span>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  // import RegistrasiHeader from '../registrasi/RegistrasiHeader.vue'
  // import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      // 'header-register': RegistrasiHeader,
    },

    data: () => ({
      // Form Data:
      selectedPropinsi: [
        {
          label: 'Pilih Propinsi',
        },
      ],
      propinsi: [],

      selectedKabupatenKota: [
        {
          label: 'Pilih Kabupaten/ Kota',
        },
      ],
      kabupatenKota: [],

      sumberInfo: '',

      referensi: '',

      jenisKeanggotaan: 'profesional',

      disableButton: false,

      // Dialog:
      dialog1: false,

      // Permission:
      // userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
    },

    watch: {
    },

    created () {
      // this.initialize()
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          prt_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/partner/company/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit () {
        this.$router.push({ name: 'Login' })
        // console.log(this.jenisKeanggotaan)
        // switch (this.jenisKeanggotaan) {
        //   case 'profesional':
        //     this.$router.push({ name: 'RegisterProfesionalDataDiri' })
        //     break

        //   case 'universitas':
        //     this.$router.push({ name: 'RegisterUniversitas' })
        //     break
        // }
      },

      navBack () {
        this.$router.push({ name: 'Mitra' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txt-login {
    @extend .h-4;
    color: $gray-50;
    font-weight: bold;
  }

  .txt-sub-1 {
    @extend .p-1;
    color: $gray-50;
  }

  .txt-sub-2 {
    @extend .p-1;
    color: $gray-50;
    font-style: italic;
  }

  .txt-sub-3 {
    @extend .p-2;
    color: $gray-50;
  }

  .txt-sub-4 {
    @extend .p-2;
    color: $blue-1;
    cursor: pointer;
    font-weight: bold;
  }

  .txt-sub-5 {
    @extend .p-2;
    color: $gray-80;
  }

  .txt-sub-6 {
    @extend .h-5;
    color: $black-1;
    font-weight: bold;
  }

  .txt-btn-login {
    @extend .h-5;
    color: #FFFFFF;
    font-weight: bold;
  }

  .btn-login {
    color: $green-2;
  }

  .symbol-required {
    color: $red-1;
  }

  .dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
  }

  .txt-12-green-2-bold {
    @extend .p-2;
    color: $green-2;
    font-weight: bold;
  }

  .txt36-black-bold {
    @extend .h-3;
    color: $black-1;
    font-weight: bold;
  }

  .txt16-black-bold {
    @extend .p-1;
    color: $black-1;
    font-weight: bold;
  }
</style>
